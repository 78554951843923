import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  Paper,
  Button,
  Avatar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAddPaymentMutation } from "../../../app/api/loans/loanApiSlice";
import BorrowerSignature from "../../../components/signature/BorrowerSignature";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../../app/api/auth/authSlice";
import socketService from "../../../app/api/ws/initializeWebSocketService";
import { ModalData } from "../../../components/modals/Modal";
import UploadButton from "../membersForm/UploadFileData";

function WithdrawData({
  data,
  onClose,
  setSnackBarMsg,
  setSnackBarColor,
  setPopoverOpen,
}) {
  const [withdrawValue, setWithdrawValue] = React.useState(
    data.computed.lastPaidAmount
  );
  const [error, setError] = useState(false);

  const maxValue = parseFloat(data.computed.lastPaidAmount); // set your maximum value here

  const [addWithdraw, { isLoading }] = useAddPaymentMutation();

  const user_id = useSelector(selectCurrentUserId);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState(null);
  const [modalType, setmodalType] = useState(null);
  const [signSrcBorrower, setsignSrcBorrower] = useState("");
  const [withdrawNotes, setWithdrawNotes] = useState("");
  const [signSrcCo, setsignSrcCo] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [attachments, setAttachments] = useState("");

  const handleWithdrawNotesChange = (e) => {
    setWithdrawNotes(e.target.value);
  };
  const handleButtonClick = () => {
    setModalOpen(false);
  };

  const handleImg = (imgSrc) => {
    switch (modalType) {
      case "cborrower":
        setsignSrcBorrower(imgSrc);
        break;
      case "ccoMaker":
        setsignSrcCo(imgSrc);
        break;
      case "ccamera":
        setImgSrc(imgSrc);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (files) => {
    setAttachments(files.target.files[0]);
  };

  const handleWithdrawChange = (e) => {
    const value = e.target.value;
    if (value === "" || !isNaN(value)) {
      const numericValue = parseFloat(value, 10);
      if (isNaN(numericValue) || numericValue <= maxValue) {
        setWithdrawValue(value);
        setError(false); // reset error if within range
      } else {
        setError(true); // set error if exceeding max value
      }
    }
  };
  const handleWithdraw = async () => {
    try {
      const formData = new FormData();
      formData.append("payment_amount", withdrawValue);
      formData.append("loanId", data.computed.loanId);
      formData.append("added_by", user_id);
      formData.append("num_payment", 1);
      formData.append("payment_date", dayjs());
      formData.append("effectived_date", dayjs());
      formData.append("loan_amount", data.computed.loanAmount);
      formData.append("loan_interest", data.computed.loanInterest);
      formData.append("new_interest", data.computed.loanInterest);
      formData.append("apply_to_interest", 0);
      formData.append("update_interest", 0);
      formData.append("payment_change", 0);
      formData.append("remaining_balance", data.computed.remaining_balance);
      formData.append("status", 1);
      formData.append("signature", signSrcBorrower);
      formData.append("notes", withdrawNotes);
      formData.append("attachment", attachments);
      formData.append("image_url", imgSrc);
      formData.append("type", "withdraw");

      const res = await addWithdraw(formData);
      if (res) {
        socketService.sendMessage({ type: "refresh" });
        onClose();
        setSnackBarMsg("Withdrawal Successful");
        setSnackBarColor("success");
        setPopoverOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSign = (event) => {
    const value = event.target.getAttribute("data-value");
    console.log(value);
    setmodalType("c" + value);
    setModalOpen(true); // Open the modal
  };
  let content = (
    <>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack direction={"column"} spacing={2}>
          <Stack
            direction={{ xs: "column" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Avatar
              alt="Remy Sharp"
              src={imgSrc}
              sx={{ width: 150, height: 150 }}
              variant="square"
            />

            <Button
              data-value="camera"
              variant="contained"
              onClick={handleSign}
              style={{ marginBottom: "10px" }}
            >
              Capture image
            </Button>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Withdraw Amount:</Typography>
            <TextField
              label="Amount"
              type="text"
              value={withdrawValue}
              onChange={handleWithdrawChange}
              style={{ width: "100%" }}
              error={error}
              helperText={error ? `Maximum input value is ${maxValue}` : ""}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ display: "flex", alignItems: "center" }}
            spacing={2}
          >
            <Typography sx={{ width: "150px" }}>Notes:</Typography>
            <TextField
              label="Notes"
              type="text"
              value={withdrawNotes}
              onChange={handleWithdrawNotesChange}
              style={{ width: "100%" }}
              multiline
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <BorrowerSignature
              handleSign={handleSign}
              signSrcBorrower={signSrcBorrower}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ justifyContent: "center", alignItems: "center" }}
            spacing={2}
          >
            <UploadButton
              selectedImage={attachments}
              handleFileChange={handleFileChange}
              uploadValue="attach"
              title="attachments"
            />
          </Stack>
        </Stack>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          width: "100%",
          justifyContent: "right",
          textAlign: "right",
        }}
      >
        <LoadingButton
          onClick={handleWithdraw}
          loading={isLoading}
          variant="contained"
          color="primary"
        >
          Withdraw
        </LoadingButton>
      </Paper>
      {modalOpen && (
        <ModalData
          closeModal={handleButtonClick}
          onSubmit={handleButtonClick}
          onCancel={handleButtonClick}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          profileName=""
          signatureImg={handleImg}
          cameraImg={handleImg}
        />
      )}
    </>
  );
  return content;
}

export default WithdrawData;
