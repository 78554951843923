import { Typography, TextField, Paper, Stack, Box } from "@mui/material";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useUpdateLoanMutation } from "../../../app/api/loans/loanApiSlice";
import { selectCurrentUserId } from "../../../app/api/auth/authSlice";
import { useSelector } from "react-redux";
import socketService from "../../../app/api/ws/initializeWebSocketService";

function LoanEdit(data) {
  console.log(data);
  const [loanValue, setLoanValue] = React.useState(data.data.loan_amount);
  const [loanInterest, setLoanInterest] = React.useState(
    data.data.loan_interest
  );
  const [loanTerms, setLoanTerms] = React.useState(data.data.loan_terms);
  const [updateLoan, { isSuccess, isLoading }] = useUpdateLoanMutation();
  const user_id = useSelector(selectCurrentUserId);

  const handleLoanValueChange = (e) => {
    setLoanValue(e.target.value);
  };

  const handleLoanInterestChange = (e) => {
    setLoanInterest(e.target.value);
  };

  const handleLoanTerms = (e) => {
    setLoanTerms(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      loan_amount: loanValue,
      loan_interest: loanInterest,
      loan_terms: loanTerms,
      added_by: user_id,
    };
    try {
      const res = await updateLoan({
        id: data.data.computed.loanId,
        data: formData,
      });
      if (res) {
        socketService.sendMessage({ type: "refresh" });
        data.setSnackBarMsg("Successfully updated loan");
        data.setSnackBarColor("success");
        data.setPopoverOpen(true);
        data.onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "10px" }}>
        <Box
          component="form"
          validate="true"
          onSubmit={handleSubmit}
          sx={{ mt: 1 }}
          className="memberFormData"
        >
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
              spacing={2}
            >
              <Typography sx={{ width: "150px" }}>Loan Amount:</Typography>
              <TextField
                label="Amount"
                type="text"
                value={loanValue}
                onChange={handleLoanValueChange}
                style={{ width: "100%" }}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
              spacing={2}
            >
              <Typography sx={{ width: "150px" }}>Loan Interest:</Typography>
              <TextField
                label="Interest"
                type="text"
                value={loanInterest}
                onChange={handleLoanInterestChange}
                style={{ width: "100%" }}
              />
            </Stack>
            {data.data.loan_type === 0 && (
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }}>Loan Term:</Typography>
                <TextField
                  label="Loan Term"
                  type="text"
                  value={loanTerms}
                  onChange={handleLoanTerms}
                  style={{ width: "100%" }}
                />
              </Stack>
            )}
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{
                textAlign: "right",
                justifyContent: "right",
                alignItems: "right",
              }}
            >
              <LoadingButton
                color="secondary"
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                type="submit"
                sx={{ fontSize: "1.5rem" }}
              >
                <span>Save</span>
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </div>
  );
}

export default LoanEdit;
