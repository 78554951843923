import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useAddUserMutation,
  useGetCardInfoQuery,
  useGetCoMakerQuery,
  useGetUsersQuery,
} from "../app/api/users/usersApiSlice";
import { useApproveLoanMutation } from "../app/api/loans/loanApiSlice";
import { selectCurrentUserId } from "../app/api/auth/authSlice";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import socketService from "../app/api/ws/initializeWebSocketService";

const useMemberForm = () => {
  const loanTypes = [{ title: "7" }, { title: "10" }, { title: "15" }];
  const { control, handleSubmit, watch, setValue } = useForm();
  const [loanValue, setLoanValue] = useState("");
  const [genderValue, setGenderValue] = useState("0");
  const [memberGroupValue, setMemberGroupValue] = useState("");
  const [borrowerValue, setBorrowerValue] = useState("0");
  const [effectiveDate, setEffectiveDate] = useState(dayjs());
  const [applyDate, setApplyDate] = useState(dayjs());
  const [notifMessage, setNotifMessage] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [notifType, setnotifType] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [comakerSrc, setcomakerSrc] = useState("");
  const [signSrcBorrower, setsignSrcBorrower] = useState("");
  const [signSrcCo, setsignSrcCo] = useState("");
  const [coMakerValue, setcoMakerValue] = useState("");
  const [civilStatusValue, setCivilStatus] = useState("0");
  const user_id = useSelector(selectCurrentUserId);
  const [modalOpen, setModalOpen] = useState(false);
  const [reqDataValue, setmodalValue] = useState(null);
  const [modalTitle, setmodalTitle] = useState(null);
  const [modalType, setmodalType] = useState(null);
  const [createMember, { isLoading }] = useAddUserMutation();
  const [approveLoan] = useApproveLoanMutation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loanLabelType, setloanLabelType] = useState("0");
  const [monthlyTerm, setmonthlyTerm] = useState(null);
  const [borrower_id, setBorrowerId] = useState(null);
  const [comaker_id, setComakerId] = useState(null);
  const [promisory_note, setPromisoryNote] = useState(null);
  const [certification_doc, setCertificationDoc] = useState(null);
  const [acknowledgement, setAcknowledgement] = useState(null);
  const { id, type } = useParams(); // Get the id parameter from the URL
  const [comId, setComId] = useState(null);
  const [loanData, setLoanData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [getCurrentLoanId, setCurrentLoanId] = useState(null);
  const [modalMemberOpen, setModalMemberOpen] = useState(false);
  const [modalMemberType, setModalMemberType] = useState(null);
  const [loanInterest, setLoanInterest] = useState(null);
  const [dialogType, setDialogType] = useState(null);
  const [userFetchData, setUserFetchData] = useState(null);

  const Navigate = useNavigate();

  const { data: userData, isLoading: userLoading } = useGetUsersQuery(
    id || "",
    {
      skip: !id,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: userCardData, isLoading: cardLoading } = useGetCardInfoQuery(
    id || "",
    { skip: !id, refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const maskCreditCardNumber = (creditCardNumber) => {
    const visibleDigits = 4; // Number of visible digits at the end
    const maskedDigits = creditCardNumber
      .slice(0, -visibleDigits)
      .replace(/\d/g, "*");
    const visiblePart = creditCardNumber.slice(-visibleDigits);
    return maskedDigits + visiblePart;
  };

  useEffect(() => {
    if (userCardData && userCardData.length > 0 && !cardLoading) {
      setValue("cardNumber", maskCreditCardNumber(userCardData[0].card_number));
      setValue("cardCVC", userCardData[0].cvc);
      setValue("cardPin", userCardData[0].pin);
    }
  }, [userCardData, cardLoading, id, setValue]);

  useEffect(() => {
    if (id && !userLoading) {
      userData.ids.forEach((userId) => {
        if (userData.entities[userId]) {
          const user = userData.entities[userId];
          console.log(user);
          setImgSrc(user.attachments.image_profile);
          setValue("firstName", user.borrower.firstname);
          setValue("middleName", user.borrower.middlename);
          setValue("lastName", user.borrower.lastname);
          setMemberGroupValue(user.borrower.membersgroup);
          setValue("address", user.borrower.address);
          setValue("phoneNumber", user.contact.phonenumber);
          setValue("telePhoneNumber", user.contact.telephone);
          setValue("emailAddress", user.contact.email);
          setGenderValue(user.borrower.gender);
          setCivilStatus(user.borrower.civil_status);
          setComId(user.comaker);
          setloanLabelType(user.loans[0].loan_type);
          setValue("applyDate", user.loans[0].applied_date);
          setLoanData(user.loans);
          setValue("rank", user.borrower.rank);
          setValue("serialNumber", user.borrower.serial);
          setValue("civilStatus", user.borrower.civil_status);
          setValue("unitAssign", user.borrower.unitassign);
          setsignSrcBorrower(user.attachments.signature);
          setValue("imageBorrower", user.attachments.image_profile);
          setcoMakerValue(user.comaker.name.firstname);
          setValue("middlenameCo", user.comaker.name.middlename);
          setValue("lastnameCo", user.comaker.name.lastname);
          setValue("addressCo", user.comaker.name.address);
          setcomakerSrc(user.comaker.attachments.image);
          setsignSrcCo(user.comaker.attachments.signature);
          setValue("signatureComaker", user.comaker.attachments.signature);
          setValue("imageComaker", user.comaker.attachments.image);
          setEffectiveDate(
            dayjs(user.loans[0].effective_date).format("YYYY-MM-DD")
          );
          setValue("paymentEffectiveDate", user.loans[0].effective_date);
          if (parseInt(user.borrower.civil_status) === 1) {
            setValue("firstNameSpouse", user.spouse.firstname);
            setValue("middleNameSpouse", user.spouse.middlename);
            setValue("lastNameSpouse", user.spouse.lastname);
          }
          setUserFetchData(user);
        }
      });
    }
  }, [userData, userLoading, id, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    // Append each key-value pair to the FormData instance
    Object.entries({
      ...data,
      signBorrower: signSrcBorrower || "",
      signCo: signSrcCo,
      imgSrc: imgSrc,
      loanType: loanValue,
      gender: genderValue,
      memberGroup: memberGroupValue,
      effective_date: effectiveDate,
      borrowerType: borrowerValue,
      comaker_name: coMakerValue,
      added_by: user_id,
      civil_status: civilStatusValue,
      applyDate: applyDate,
      loanLabelType: loanLabelType,
      monthlyTerm: monthlyTerm,
      comakerSrc: comakerSrc,
      image_borrower: borrower_id,
      image_comaker: comaker_id,
      image_promisory: promisory_note,
      image_certification: certification_doc,
      image_acknowledgement: acknowledgement,
      type: type,
      user_id: id,
    }).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const userData = await createMember(formData).unwrap(); // Pass formData directly
      if (userData) {
        let msg =
          type === "edit"
            ? "Successfully updated the borrower"
            : "Successfully added a new borrower";
        setNotifMessage(msg);
        setnotifType("success");
        setPopoverOpen(true);
        socketService.sendMessage({ type: "refresh" });
        let redirectUrl = "/members/loans/" + userData.id;
        setTimeout(() => {
          Navigate(redirectUrl);
        }, 3000); // 3000 milliseconds = 3 seconds, you can change it to 5000 for 5 seconds
      }
    } catch (err) {
      if (!err?.originalStatus) {
        console.log("No Server Response");
      } else if (err.originalStatus === 400) {
        console.log("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        console.log("Unauthorized user");
      } else {
        console.log("Error to saved changes");
      }
    }
  };

  const handleGenderChange = (event, value) => {
    setGenderValue(value || "");
  };

  const handleMemberGrpChange = (event, value) => {
    setMemberGroupValue(value);
  };

  const handleLoanTypeChange = (event, value) => {
    setLoanValue(value);
  };

  const handlecivilStatsChange = (event, value) => {
    setCivilStatus(value);
  };

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  const handleSign = (event) => {
    const value = event.target.getAttribute("data-value");
    setmodalType("c" + value);
    setModalOpen(true); // Open the modal
  };

  const handleBorrowerChange = (event, value) => {
    setBorrowerValue(value);
  };

  const handleloanLabelType = (event, value) => {
    setloanLabelType(value);
    if (value === 1) {
      setmonthlyTerm(2);
    } else {
      setmonthlyTerm(0);
    }
  };

  const handleButtonMemberClick = () => {
    setModalMemberOpen(false);
  };

  const handleImg = (imgSrc) => {
    switch (modalType) {
      case "cborrower":
        setsignSrcBorrower(imgSrc);
        break;
      case "ccamera":
        setImgSrc(imgSrc);
        break;
      case "ccoMaker":
        setsignSrcCo(imgSrc);
        break;
      case "ccameraComaker":
        setcomakerSrc(imgSrc);
        break;
      default:
        break;
    }
  };

  // Function to handle file input change
  const handleFileChange = (event) => {
    switch (event.target.dataset.value) {
      case "borrower_id":
        setBorrowerId(event.target.files[0]);
        break;
      case "comaker_id":
        setComakerId(event.target.files[0]);
        break;
      case "promisory_note":
        setPromisoryNote(event.target.files[0]);
        break;
      case "certification_doc":
        setCertificationDoc(event.target.files[0]);
        break;
      case "acknowledgement":
        setAcknowledgement(event.target.files[0]);
        break;
      default:
        break;
    }
    //const file = event.target.files[0];
    //setSelectedImage(file);
  };

  const handlePaymentModal = (data) => {
    setmodalValue(data);
    setmodalTitle(data.btnValue);

    switch (data.type) {
      case "coMaker":
      case "borrower":
      case "camera":
      case "cameraComaker":
        setmodalType("c" + data.type);
        setModalOpen(true);
        break;
      case "newLoan":
      case "Add Payment":
      case "Reloan":
      case "Withdraw":
      case "view more..":
        setModalMemberType("f" + data.type);
        setModalMemberOpen(true);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    setModalOpen(false);
  };

  const handleDialogYes = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let status = 2;
    let paymentEfffectiveDate = null;
    let msg = "";
    let msgColor = "";
    const value = parseInt(e.currentTarget.value);
    if (parseInt(value) === 2) {
      status = 2;
      msg = "Successfully Approved the loan Application";
      msgColor = "success";
      paymentEfffectiveDate = effectiveDate;
    } else {
      status = 1;
      msg = "Successfully Rejected the loan Application";
      msgColor = "error";
    }

    try {
      const statusData = {
        status: status,
        added_by: user_id,
        loan_id: parseInt(getCurrentLoanId),
        effectiveDate: paymentEfffectiveDate,
      };
      const result = await approveLoan(statusData).unwrap();
      if (result) {
        setNotifMessage(msg);
        setnotifType(msgColor);
        setPopoverOpen(true);
        setDialogOpen(false);
        socketService.sendMessage({ type: "refresh" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDialogOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentLoanId(e.target.dataset.id);
    setDialogType(e.currentTarget.value);
    switch (parseInt(e.currentTarget.value)) {
      case 1:
        setDialogContent("Are you sure you want to approve this loan?");
        break;
      case 2:
        setDialogContent("Are you sure you want to reject this loan?");
        break;
      default:
        break;
    }
    setDialogOpen(true);
  };

  const handleDiloagClose = () => {
    setDialogOpen(false);
  };
  // Define other handler functions...

  return {
    borrower_id,
    comaker_id,
    promisory_note,
    certification_doc,
    acknowledgement,
    loanTypes,
    type,
    monthlyTerm,
    loanLabelType,
    handleFileChange,
    setSelectedImage,
    selectedImage,
    comakerSrc,
    setApplyDate,
    applyDate,
    control,
    handleSubmit,
    loanValue,
    setLoanValue,
    genderValue,
    setGenderValue,
    memberGroupValue,
    setMemberGroupValue,
    borrowerValue,
    setBorrowerValue,
    effectiveDate,
    setEffectiveDate,
    notifMessage,
    setNotifMessage,
    popoverOpen,
    setPopoverOpen,
    notifType,
    setnotifType,
    imgSrc,
    setImgSrc,
    signSrcBorrower,
    setsignSrcBorrower,
    signSrcCo,
    setsignSrcCo,
    coMakerValue,
    setcoMakerValue,
    civilStatusValue,
    setCivilStatus,
    user_id,
    modalOpen,
    setModalOpen,
    modalTitle,
    setmodalTitle,
    modalType,
    setmodalType,
    createMember,
    isLoading,
    onSubmit,
    handleGenderChange,
    handleMemberGrpChange,
    handleLoanTypeChange,
    handlecivilStatsChange,
    handleCloseSnackbar,
    handleSign,
    handleButtonClick,
    handleBorrowerChange,
    handleImg,
    handleloanLabelType,
    watch,
    id,
    loanData,
    handlePaymentModal,
    reqDataValue,
    dialogOpen,
    handleDialogOpen,
    handleDiloagClose,
    dialogContent,
    handleDialogYes,
    modalMemberOpen,
    setModalMemberOpen,
    modalMemberType,
    setModalMemberType,
    handleButtonMemberClick,
    setLoanInterest,
    loanInterest,
    dialogType,
    userCardData,
    //comakerData,
    userData,
    userFetchData,
    setValue,
    setcomakerSrc,
    // Add other handler functions here...
  };
};

export default useMemberForm;
