import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Member.css";
import { Controller } from "react-hook-form";
import { useGetCoMakerQuery } from "../../../app/api/users/usersApiSlice";
const CoMakerContent = ({
  coMakerValue,
  control,
  setcoMakerValue,
  handleSign,
  comakerSrc,
  type,
  watch,
  setValue,
  setcomakerSrc,
  setsignSrcCo,
}) => {
  const [coMakerOption, setCoMakerOption] = useState([{}]);
  const { data: comakerData, isLoading: comakerLoading } =
    useGetCoMakerQuery("");
  useEffect(() => {
    let coMakerOpt = [];
    if (comakerData && !comakerLoading) {
      comakerData.map((comaker) => {
        coMakerOpt.push({
          title: comaker.name.firstname,
          mname: comaker.name.middlename,
          lname: comaker.name.lastname,
          address: comaker.name.address,
          image: comaker.attachments.image,
          signature: comaker.attachments.signature,
        });
      });
    }
    setCoMakerOption(coMakerOpt);
  }, [comakerData, comakerLoading]);

  const handlecoMakerChange = (event, value) => {
    console.log(coMakerOption.find((option) => option.title === value).lname);
    setValue(
      "middlenameCo",
      coMakerOption.find((option) => option.title === value).mname
    );
    setValue(
      "lastnameCo",
      coMakerOption.find((option) => option.title === value).lname
    );
    setValue(
      "addressCo",
      coMakerOption.find((option) => option.title === value).address
    );
    setsignSrcCo(
      coMakerOption.find((option) => option.title === value).signature
    );
    setcomakerSrc(coMakerOption.find((option) => option.title === value).image);
    setcoMakerValue(value);
  };
  return (
    <Grid item xs={12} md={12}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} className="gridLabelTitle">
        <Typography variant="h6" fontWeight="bold">
          CO-MAKER INFORMATION
        </Typography>
      </Grid>
      <Stack spacing={2} marginTop={2} direction={"row"}>
        <Grid item xs={8}>
          <Stack spacing={2}>
            {type === "reloan" || type === "view" ? (
              <>
                <Typography
                  variant="span"
                  sx={{ fontSize: 18 }}
                  textTransform={"capitalize"}
                >
                  Name:{" "}
                  <Typography variant="span" fontWeight={700}>
                    {coMakerValue +
                      " " +
                      watch("middlenameCo") +
                      " " +
                      watch("lastnameCo")}
                  </Typography>
                </Typography>
                <Typography
                  variant="span"
                  sx={{ fontSize: 18 }}
                  textTransform={"capitalize"}
                >
                  Address:{" "}
                  <Typography variant="span" fontWeight={700}>
                    {watch("addressCo")}
                  </Typography>
                </Typography>
              </>
            ) : (
              <>
                <Stack direction={"row"} spacing={2}>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="coMaker"
                      freeSolo
                      options={coMakerOption.map((option) => option.title)}
                      renderInput={(params) => (
                        <TextField {...params} label="Firstname" required />
                      )}
                      onChange={(event, newValue) => {
                        // Ensure newValue is always a string or undefined
                        const coMaker = newValue || ""; // If newValue is null, set to empty string
                        setcoMakerValue(coMaker);
                        handlecoMakerChange(event, coMaker); // Call handleLoanTypeChange with the new value
                      }}
                      value={coMakerValue}
                      inputValue={coMakerValue}
                      onInputChange={(event, newInputValue) => {
                        // Ensure newInputValue is always a string or undefined
                        const coMaker = newInputValue || ""; // If newInputValue is null, set to empty string
                        setcoMakerValue(coMaker);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="middlenameCo"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Middlename" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="lastnameCo"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Lastname" fullWidth />
                      )}
                    />
                  </Grid>
                </Stack>
                <Controller
                  name="addressCo"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Address" multiline fullWidth />
                  )}
                />
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <img
              src={comakerSrc ? comakerSrc : "/assets/noimage.jpg"}
              alt="webcam"
              name="comakerImage"
              style={{ width: "100%", marginBottom: "10px" }}
            />
            {type === "reloan" || type === "view" ? null : (
              <Button
                data-value="cameraComaker"
                variant="contained"
                onClick={handleSign}
                style={{ marginBottom: "10px" }}
              >
                Capture image
              </Button>
            )}
          </Stack>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default CoMakerContent;
