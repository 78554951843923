import { useEffect, useState } from "react";
import { Tooltip, Stack, Typography, Avatar } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

function createData(
  transaction_date,
  loan_amount,
  interest,
  interest_amount,
  paid_amount,
  change,
  remaining_balance,
  status,
  apply_to_interest,
  image_url,
  signature,
  attachment
) {
  return {
    transaction_date,
    loan_amount,
    interest,
    interest_amount,
    paid_amount,
    change,
    remaining_balance,
    status,
    apply_to_interest,
    image_url,
    signature,
    attachment,
  };
}
const baseUrl = process.env.REACT_APP_BACKEND_URL;
export default function TransactionFlexibleTable({ paymentData, isLoading }) {
  const [rows, setRows] = useState([]); // Use state instead of a mutable array
  const data = paymentData;
  useEffect(() => {
    if (!isLoading && data) {
      const newRows = data.payments.map((element) =>
        createData(
          dayjs(element.payment_date).format("MMM DD, YYYY"),
          element.loan_info.loan_amount.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          element.loan_info.loan_interest,
          (element.status === 1
            ? 0
            : parseFloat(element.loan_info.loan_amount) *
              (parseFloat(element.loan_info.loan_interest) / 100) *
              element.loan_info.loan_term
          ).toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          element.amount.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          element.payment_change.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          element.status === 1
            ? (element.loan_info.loan_amount + element.amount).toLocaleString(
                "en-US",
                {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            : element.payment_change > -1
            ? element.remaining_balance.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : element.loan_info.loan_amount.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
          element.status,
          element.apply_to_interest,
          element.withdraw_info ? element.withdraw_info.image_url : "",
          element.withdraw_info ? element.withdraw_info.signature : "",
          element.withdraw_info ? element.withdraw_info.attachment : ""
        )
      );
      setRows(newRows); // Update state instead of mutating the array directly
    }
  }, [data, isLoading]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Transaction Date
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Principal Amount
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Interest
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Interest Amount
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Paid Amount
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Change
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Remaining Balance
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Status
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Withdraw Image
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Withdraw Signature
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: 18 }} align="center">
              Withdraw Attachments
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: "lightgray", // Change the background color as desired
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: 400, fontSize: 16 }}
                align="center"
              >
                {row.transaction_date}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 400, fontSize: 16 }}>
                ₱{row.loan_amount}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 400, fontSize: 16 }}>
                {row.interest}%
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 400, fontSize: 16 }}>
                ₱{row.interest_amount}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: row.change < -1 ? "red" : "black",
                }}
              >
                ₱{row.paid_amount}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 400, fontSize: 16 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignContent: "right", justifyContent: "right" }}
                >
                  ₱{row.change}
                  <Typography>
                    {row.apply_to_interest === 1 ? (
                      <>
                        <Tooltip title="Exclude Interest">
                          <InfoOutlinedIcon
                            fontSize="14"
                            color="error"
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 400, fontSize: 16 }}>
                ₱{row.remaining_balance}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 400, fontSize: 16 }}>
                {row.status === 1 ? "Withdraw" : "Paid"}
              </TableCell>

              <TableCell
                align="center"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <PhotoProvider>
                  <PhotoView src={row.image_url}>
                    <Avatar
                      alt="Withdraw Image"
                      src={row.image_url}
                      sx={{ width: 50, height: 50, cursor: "pointer" }}
                      variant="square"
                    />
                  </PhotoView>
                </PhotoProvider>
              </TableCell>
              <TableCell
                align="center"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <PhotoProvider maskOpacity={0.1}>
                  <PhotoView src={row.signature}>
                    <Avatar
                      alt="Withdraw Signature"
                      src={row.signature}
                      sx={{ width: 50, height: 50, cursor: "pointer" }}
                      variant="square"
                    />
                  </PhotoView>
                </PhotoProvider>
              </TableCell>
              <TableCell
                align="center"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                {row.attachment !== "" ? (
                  <PhotoProvider>
                    <PhotoView src={`${baseUrl}/uploads/${row.attachment}`}>
                      <Avatar
                        alt="Withdraw Attachment"
                        src={`${baseUrl}/uploads/${row.attachment}`}
                        sx={{ width: 50, height: 50, cursor: "pointer" }}
                        variant="square"
                      />
                    </PhotoView>
                  </PhotoProvider>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
