import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ModalPrintPreview } from "../../../components/modals/ModalPrintPreview";

function MembersInfo(data) {
  const navigate = useNavigate();
  const [modalPreview, setModalPreview] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [comakerData, setComakerData] = React.useState({});
  const [loanData, setLoanData] = React.useState({});
  const [prevLoanData, setPrevLoanData] = React.useState([{}]);
  const [modalPreviewType, setModalPreviewType] = React.useState("");
  const { id } = useParams();
  //const { watch } = data;

  useEffect(() => {
    if (data) {
      let loanData = [];
      let prevLoanData = [];
      data.loanData.map((loan) => {
        if (loan.status !== undefined) {
          console.log(loan);
          let monthly_payment = 0;
          let interest =
            (parseFloat(loan.loans.amount) / 100) * loan.loans.loan_interest;
          let amount_interest = parseFloat(interest) * loan.loans.terms;
          let total_payment = parseFloat(loan.loans.amount) + amount_interest;
          if (loan.status === 2) {
            monthly_payment = total_payment / loan.loans.terms;
            loanData.push({
              amount: loan.loans.amount,
              loan_type: loan.loan_type,
              interest: loan.loans.loan_interest,
              term: loan.loans.terms,
              loanId: loan.id,
              payment: loan.payments,
              effectived_date: loan.effectived_date,
              status: loan.status,
              loan_type: loan.loan_type,
              monthly_payment: monthly_payment,
              interest_amount: interest,
              loan_purpose: loan.loans.loan_purpose,
              attachments: loan.attachments,
            });
          } else if (loan.status === 1 || loan.status === 3) {
            prevLoanData.push({
              amount: loan.loans.amount,
              loan_type: loan.loan_type,
              interest: loan.loans.loan_interest,
              term: loan.loans.terms,
              loanId: loan.id,
              payment: loan.payments,
              effectived_date: loan.effectived_date,
              status: loan.status,
              loan_type: loan.loan_type,
              monthly_payment: monthly_payment,
              interest_amount: interest,
              loan_purpose: loan.loans.loan_purpose,
              attachments: loan.attachments,
            });
          }
        }
      });
      setLoanData(loanData);
      setPrevLoanData(prevLoanData);
      setUserData(data.userData);
      setComakerData(data.comaker);
    }
  }, [data]);

  const handlePrintPreview = () => {
    setModalPreview(true);
  };

  const handleModalPreviewClose = () => {
    setModalPreview(false);
  };

  const handleCreateLoan = () => {
    data.handleCreateLoan({
      id: data.loginUser,
      type: "newLoan",
      loginUser: data.loginUser,
    });
  };
  console.log(data.comaker);
  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/operator-dashboard")}
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handlePrintPreview}
            sx={{ float: "right" }}
          >
            Print Preview
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateLoan}
            sx={{ float: "right", right: "10px" }}
          >
            Add new loan
          </Button>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: "20px" }}>
              <Typography variant="h6" fontWeight="bold">
                Borrower Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>Name:</Typography>
                    <Typography>
                      {data.userData ? (
                        data.userData.name
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                  </Stack>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>Address:</Typography>
                    <Typography>
                      {data.userData ? (
                        data.userData.address
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                  </Stack>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>
                      Contact Number:
                    </Typography>
                    <Typography>
                      {data.userData ? (
                        data.userData.contact_number
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                  </Stack>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>
                      Member Group:
                    </Typography>
                    <Typography>
                      {data.userData ? (
                        data.userData.member_group
                      ) : (
                        <Skeleton animation="wave" />
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ textAlign: "center", justifyContent: "center" }}
                >
                  <Stack
                    spacing={2}
                    marginTop={2}
                    sx={{
                      alignContent: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={data.userData ? data.userData.image : ""}
                      variant="rounded"
                      sx={{ width: 100, height: 100 }}
                    />
                    {data.userData.signature ? (
                      <Avatar
                        alt="Remy Sharp"
                        src={data.userData ? data.userData.signature : ""}
                        variant="rounded"
                        sx={{ width: 100, height: 20 }}
                      />
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Stack>
                  <Divider sx={{ marginTop: 2 }} />
                  <Typography>Signature</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: "20px" }}>
              <Typography variant="h6" fontWeight="bold">
                Co-maker Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>Name:</Typography>
                    <Typography>{data.comaker.name}</Typography>
                  </Stack>
                  <Stack spacing={2} marginTop={2} direction={"row"}>
                    <Typography sx={{ width: "150px" }}>Address:</Typography>
                    <Typography>{data.comaker.address}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ textAlign: "center", justifyContent: "center" }}
                >
                  <Stack
                    spacing={2}
                    marginTop={2}
                    sx={{
                      alignContent: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={data.comaker.image}
                      variant="rounded"
                      sx={{ width: 100, height: 100 }}
                    />
                    {data.comaker.signature ? (
                      <Avatar
                        alt="Remy Sharp"
                        src={data.comaker.signature}
                        variant="rounded"
                        sx={{ width: 100, height: 20 }}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Divider sx={{ marginTop: 2 }} />
                  <Typography>Signature</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      {modalPreview && (
        <ModalPrintPreview
          closeModal={handleModalPreviewClose}
          onSubmit={handleModalPreviewClose}
          onCancel={handleModalPreviewClose}
          openModal={modalPreview}
          userData={userData}
          comakerData={comakerData}
          loanData={loanData}
          prevLoanData={prevLoanData}
          type={"printUserData"}
        />
      )}
    </div>
  );
}

export default MembersInfo;
